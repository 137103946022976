

















































































































import mixins from 'vue-typed-mixins'
import DialogMixins from '@/components/dialogs/mixins/DialogMixins'
import EmployeeCarousel from '@/components/dialogs/components/BookingDialog/components/EmployeeCarousel.vue'
import BookingTimePicker from '@/components/BookingTimePicker.vue'
import BookingDialogModel from '@/calendesk/models/BookingDialogModel'
import { renderDuration } from '@/calendesk/filters/renderDuration'
import BookingTimeSlot from '@/calendesk/models/BookingTimeSlot'
import { mapActions } from 'vuex'
import Employee from '@/calendesk/models/DTO/Response/Employee'
import { DialogTypes } from '@/components/dialogs/DialogTypes'
import DialogSize from '@/calendesk/models/DialogSize'
import Dialog from '@/calendesk/models/Dialog'
import Service from '@/calendesk/models/DTO/Response/Service'
import BookingEvent from '@/calendesk/models/BookingEvent'
import parseRawBookingTimeSlots from '@/calendesk/tools/parseRawBookingTimeSlots'
import CTimeZonesSelect from '@/components/CTimeZonesSelect.vue'
import moment from 'moment'
import { DateTimeFormats } from '@/calendesk/models/DateTimeFormats'
import CLocationSelect from '@/components/CLocationSelect.vue'
import ServiceLocation from '@/calendesk/models/DTO/Response/ServiceLocation'
import BookingRawSlot from '@/calendesk/models/BookingRawSlot'

export default mixins(DialogMixins).extend({
  name: 'BookingDialog',
  components: {
    CLocationSelect,
    CTimeZonesSelect,
    EmployeeCarousel,
    BookingTimePicker
  },
  data () {
    return {
      isFetchingTimeSlots: false,
      minDate: new Date().toISOString().substr(0, 10),
      allEmployeesTimeSpots: null as Record<string, any> | null,
      selectedDuration: null as number | null,
      selectedEmployee: null as Employee | null,
      selectedDate: null as string | null,
      selectedTimeSlot: null as BookingTimeSlot | null,
      isReady: false,
      firstDateSet: false,
      customerTimeZone: null as null | string,
      selectedLocation: null as ServiceLocation | null
    }
  },
  computed: {
    getSectionConfigurationData (): Record<string, any>|null {
      if (this.dialog &&
        this.dialog.data &&
        this.dialog.data.configurationData &&
        this.dialog.data.configurationData.configuration) {
        return this.dialog.data.configurationData.configuration as Record<string, any>
      }

      return null
    },
    locationAlwaysSelected (): boolean {
      return this.getSectionConfigurationData?.wb_initial_location_select__checkbox__ !== false
    },
    isLocationRequired (): boolean {
      return this.appConfiguration.schedulesV2enabled && this.serviceLocations.length > 0
    },
    showLocationError (): boolean {
      return this.isLocationRequired && !this.selectedLocation
    },
    serviceLocations (): Array<ServiceLocation> {
      if (
        this.owner &&
        this.owner.service &&
        this.owner.service.locations
      ) {
        return this.owner.service.locations
      }

      return []
    },
    owner (): BookingDialogModel {
      return this.dialog.data.dialogData as BookingDialogModel
    },
    price (): number {
      if (this.owner) {
        return this.owner.serviceType ? this.owner.serviceType.price : this.owner.service.price
      }

      return 0
    },
    serviceMaxPeople (): number {
      if (this.owner && this.owner.service) {
        return this.owner.service.maxPeople
      }

      return 1
    },
    availableTimeSpots (): Array<BookingTimeSlot> {
      if (this.selectedEmployee && this.allEmployeesTimeSpots) {
        const spots = this.allEmployeesTimeSpots[this.selectedEmployee.id][this.selectedDate as string] as Array<Record<string, any>>
        if (spots && spots.length > 0) {
          const employeeId = this.selectedEmployee.id

          const formattedSpots = spots.map(spot => ({
            ...spot,
            date: spot.date || this.selectedDate,
            time: spot.time,
            max: spot.max,
            used: spot.used,
            booked: spot.booked || false,
            availableEmployeeIds: [employeeId]
          }))

          return parseRawBookingTimeSlots(formattedSpots, this.serviceMaxPeople)
        }
        return []
      }
      return this.mergedTimeSpots
    },
    mergedTimeSpots (): Array<BookingTimeSlot> {
      let spots: BookingRawSlot[] = []
      if (this.allEmployeesTimeSpots) {
        for (const key of Object.keys(this.allEmployeesTimeSpots)) {
          const dateValues = this.allEmployeesTimeSpots[key][this.selectedDate as string]
          if (dateValues && dateValues.length > 0) {
            const formattedDateValues = dateValues.map((spot: Record<string, any>) => ({
              ...spot,
              availableEmployeeIds: [parseInt(key)]
            }))
            spots = [...spots, ...formattedDateValues]
          }
        }
        return parseRawBookingTimeSlots(spots, this.serviceMaxPeople)
      }
      return []
    },
    availableEmployeesForSelectedTime (): Array<Employee> {
      const employees = Array<Employee>()

      const allEmployeesTimeSpots = this.allEmployeesTimeSpots

      if (allEmployeesTimeSpots) {
        for (const key of Object.keys(allEmployeesTimeSpots as {})) {
          const dateValues = allEmployeesTimeSpots[key][this.selectedDate as string]
          if (dateValues && dateValues.length > 0) {
            const found = dateValues.find((item: BookingTimeSlot) => item.time === this.selectedTimeSlot?.time)

            if (found) {
              const employee = (this.owner.service.employees as Employee[]).find((employee: Employee) => employee.id === Number(key))
              if (employee) {
                employees.push(employee)
              }
            }
          }
        }
      }

      return employees
    },
    dynamicDurationTimeSpots (): Array<Record<string, string | number>> {
      const slots: Array<Record<string, string | number>> = []
      let defaultTime = this.owner?.getDuration()

      if (defaultTime) {
        while (defaultTime <= 600) {
          slots.push({
            text: renderDuration(defaultTime),
            value: defaultTime
          })
          defaultTime += 5
        }
      }

      return slots
    },
    firstAvailableDate (): string | null {
      const dates: Set<string> = new Set()
      if (this.allEmployeesTimeSpots) {
        if (this.selectedEmployee && this.selectedEmployee.id && this.allEmployeesTimeSpots[this.selectedEmployee.id]) {
          for (const key of Object.keys(this.allEmployeesTimeSpots[this.selectedEmployee.id] as {})) {
            const hours = this.allEmployeesTimeSpots[this.selectedEmployee.id][key]
            if (hours && hours.length > 0) {
              dates.add(key)
            }
          }
        } else {
          for (const employeeId of Object.keys(this.allEmployeesTimeSpots as {})) {
            for (const key of Object.keys(this.allEmployeesTimeSpots[employeeId] as {})) {
              const hours = this.allEmployeesTimeSpots[employeeId][key]
              if (hours && hours.length > 0) {
                dates.add(key)
              }
            }
          }
        }
      }

      const result = [...dates].sort(function (a, b) {
        return new Date(a).getTime() - new Date(b).getTime()
      })

      if (result.length > 0) {
        return result[0]
      }

      return null
    }
  },
  watch: {
    selectedDate () {
      if (this.isReady) {
        this.fetchAvailableSlots()
      }
    },
    selectedLocation () {
      if (this.isReady) {
        this.fetchAvailableSlots()
      }
    },
    selectedDuration () {
      if (this.isReady) {
        this.fetchAvailableSlots()
      }
    },
    customerTimeZone () {
      if (this.isReady) {
        this.fetchAvailableSlots()
      }
    },
    selectedEmployee () {
      if (this.isReady) {
        this.selectedDate = this.$moment().format(DateTimeFormats.FULL)
        this.fetchAvailableSlots()
      }
    }
  },
  mounted () {
    if (!this.isTimeZoneHidden) {
      this.customerTimeZone = moment.tz.guess()
    }

    this.selectedDate = this.owner.date
    this.selectedEmployee = this.owner.employee

    if (this.owner.service.allowDynamicDuration) {
      this.selectedDuration = this.owner.getDuration()
    }

    this.fetchAvailableSlots()
    this.isReady = true
  },
  methods: {
    ...mapActions({
      fetchAvailableBookingSlots: 'booking/fetchAvailableBookingSlots'
    }),
    fetchAvailableSlots () {
      if (this.showLocationError) {
        this.isFetchingTimeSlots = false
        return
      }

      this.isFetchingTimeSlots = true
      this.selectedTimeSlot = null
      this.fetchAvailableBookingSlots({
        used_slots: 1,
        number_of_days: 180,
        service_id: this.owner?.service.id,
        start_date: this.selectedDate,
        service_type_id: this.owner?.serviceType?.id || null,
        required_time: this.selectedDuration,
        customer_time_zone: this.customerTimeZone,
        location_id: this.selectedLocation?.locationId || null
      })
        .then((data: Record<string, any>) => {
          this.allEmployeesTimeSpots = data
        })
        .catch((error) => {
          this.$log.info('fetchAvailableBookingSlots@catch', error)
        })
        .finally(() => {
          if (!this.firstDateSet && this.mergedTimeSpots.length === 0 && this.firstAvailableDate) {
            this.firstDateSet = true
            this.selectedDate = this.firstAvailableDate
          } else {
            this.isFetchingTimeSlots = false
          }
        })
    },
    bookingHandle () {
      if (this.selectedTimeSlot) {
        const userRequestedEmployee = !!this.selectedEmployee

        this.openDialog(new Dialog(
          true,
          DialogTypes.BOOKING_FORM_DIALOG,
          null,
          DialogSize.MIDDLE,
          false,
          'center',
          {
            events: [
              new BookingEvent(
                this.owner?.service as Service,
                this.selectedEmployee ? this.selectedEmployee : this.availableEmployeesForSelectedTime[0],
                this.owner?.serviceType ? this.owner.serviceType : null,
                this.selectedDate as string,
                this.selectedTimeSlot?.time as string,
                null,
                null,
                this.selectedDuration,
                this.customerTimeZone,
                userRequestedEmployee,
                this.selectedLocation
              )
            ],
            configurationData: this.dialog.data.configurationData
          },
          true))
      }
    }
  }
})
